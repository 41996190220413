import React from 'react'
import { data } from '../db/data'
import { useSanity } from './SanityContext'

function Hero() {
    const sanityData = useSanity()
    return (
        <div className='max-w-[1280px] mx-auto mt-20'> 
            <video className='w-screen rounded-2xl border-2' autoPlay loop muted playsInline>
                <source src="/demo.mp4" type='video/mp4' />
            </video>
            <div className='p-4 w-full mt-5 mb-5 flex flex-col text-center justify-center'>
                <p className='font-medium text-2xl'>Perceiving music through the lens of tradition</p>
                <p className='text-slate-500 w-[80%] mx-auto mt-4'>Music is more than just a form of art. It’s an emotion that can be experienced by anyone and everyone irrespective of any barrier. The joy of experiencing your favourite artist LIVE is only a sentiment that we strive to deliver every time we organize a show.</p>
            </div>
        </div>
    )
}

export default Hero