import React, { useEffect, useState } from 'react';
import {
    FaContao,
    FaDribbbleSquare,
    FaFacebookSquare,
    FaGithubSquare,
    FaInstagram,
    FaLocationArrow,
    FaMailBulk,
    FaMapMarked,
    FaPhone,
    FaTwitterSquare,
} from "react-icons/fa";

import sanityClient from "@sanity/client";

const client = sanityClient({
    projectId: "hv5hizmq",
    dataset: "production",
    useCdn: true,
});

function ContactUs() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        subject: 'general',
        message: ''
    });

    const [data, setData] = useState([]);

    useEffect(() => {
        client
            .fetch('*[_type == "contact_info"]')
            .then((result) => {
                result.map((item) => {
                    setData((prev) => [
                        ...prev,
                        {
                            header: item.header,
                            address: item.address,
                            email: item.email,
                            phone: item.phone
                        }
                    ])
                });
            })
            .catch((error) => {
                console.error("Error fetching data from Sanity:", error);
            });
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const emailBody = `
        First Name: ${formData.firstName}
        Last Name: ${formData.lastName}
        Email: ${formData.email}
        Phone Number: ${formData.phoneNumber}
        Subject: ${formData.subject}
        Message: ${formData.message}
      `;

        // Construct the mailto link
        const mailtoLink = `mailto:${data[0]?.email}?subject=${formData.subject}&body=${encodeURIComponent(emailBody)}`;

        // Open the user's email client
        window.location.href = mailtoLink;
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 p-1">
            <div className="bg-white shadow-lg rounded-lg flex flex-col md:flex-row max-w-4xl w-full">
                <div className="md:w-1/2 flex flex-col justify-between bg-[#571759] text-white p-8 rounded-t-lg md:rounded-l-lg md:rounded-tr-none">
                    <div>
                        <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
                        <p className="mb-6">Say something to start a live chat!</p>
                    </div>
                    <ul className="space-y-12">
                        <li className="flex items-center">
                            <FaPhone className="h-6 w-6 mr-4" size={18} />
                            {data[0]?.phone}
                        </li>
                        <li className="flex items-center">
                            <FaMailBulk className="h-6 w-6 mr-4" size={18} />
                            {data[0]?.email}
                        </li>
                        <li className="flex items-center">
                            <FaMapMarked className="h-6 w-6 mr-4" size={18} />
                            {data[0]?.address}
                        </li>
                    </ul>
                    <div className="flex mt-6 space-x-6">
                        <a className="cursor-pointer" href={data?.facebook}><FaFacebookSquare size={30} /></a>
                        <a className="cursor-pointer" href={data?.instagram}><FaInstagram size={30} /></a>
                        <a className="cursor-pointer" href={data?.twitter}><FaTwitterSquare size={30} /></a>
                    </div>
                </div>
                <div className="md:w-1/2 p-8 rounded-b-lg md:rounded-bl-none">
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label htmlFor="firstName" className="block text-gray-700">First Name</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    className="w-full px-4 py-2 border-b focus:outline-none focus:ring-2 focus:border-purple-600"
                                />
                            </div>
                            <div>
                                <label htmlFor="lastName" className="block text-gray-700">Last Name</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    className="w-full px-4 py-2 border-b focus:outline-none focus:ring-2 focus:border-purple-600"
                                />
                            </div>
                        </div>
                        <div className="mt-4">
                            <label htmlFor="email" className="block text-gray-700">Email</label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="w-full px-4 py-2 border-b focus:outline-none focus:ring-2 focus:border-purple-600"
                            />
                        </div>
                        <div className="mt-4">
                            <label htmlFor="phoneNumber" className="block text-gray-700">Phone Number</label>
                            <input
                                type="text"
                                name="phoneNumber"
                                id="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                className="w-full px-4 py-2 border-b focus:outline-none focus:ring-2 focus:border-purple-600"
                            />
                        </div>
                        <div className="mt-4">
                            <label className="block text-gray-700">Select Subject</label>
                            <div className="flex flex-wrap space-x-4 mt-2">
                                <label className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        name="subject"
                                        value="General Inquiry"
                                        checked={formData.subject === 'general'}
                                        onChange={handleChange}
                                        className="form-radio text-purple-600"
                                    />
                                    <span className="ml-2">General Inquiry</span>
                                </label>
                                {/* <label className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        name="subject"
                                        value="Billing Inquiry"
                                        checked={formData.subject === 'billing'}
                                        onChange={handleChange}
                                        className="form-radio text-purple-600"
                                    />
                                    <span className="ml-2">Billing</span>
                                </label> */}
                                <label className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        name="subject"
                                        value="Technical Inquiry"
                                        checked={formData.subject === 'technical'}
                                        onChange={handleChange}
                                        className="form-radio text-purple-600"
                                    />
                                    <span className="ml-2">Technical Support</span>
                                </label>
                            </div>
                        </div>
                        <div className="mt-4">
                            <label htmlFor="message" className="block text-gray-700">Message</label>
                            <textarea
                                name="message"
                                id="message"
                                rows="1"
                                value={formData.message}
                                onChange={handleChange}
                                className="w-full px-4 py-2 border-b focus:outline-none focus:ring-2 focus:border-purple-600"
                            />
                        </div>
                        <button
                            type="submit"
                            className="mt-4 bg-black text-white py-2 px-4 rounded-md float-right hover:bg-gray-800"
                        >
                            Send Message
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;
