export const data = {
    header_title: "An event management organization should desirably connect the right artist to the right audience through the right brand with the help of right vendors.",
    about_paragraph_1: "As right as this definition sounds, the reality was quite different. connect was the biggest disconnect that vishaka agarwal, managing director, witnessed during her extensive career in sales and events, and thus, vivikta entertainers was born.",
    about_paragraph_2: "An event management organization with a vision of connecting with audiences, brands, vendors, artists and every person in the ecosystem not just through words, but through true sentiments. it is this thought process that led to our success,",
    about_paragraph_3: "as we have conceived and executed more than 45 events in a span of just 3 years.our insightfulness and robust understanding of business makes us what we are today.and for the rest, we believe, we will let our work do the talking.",
    associations_names: [
        "Niladari Kumar",
        "Taufiq Qureshi",
        "Shankar Mahadevan",
        "Kailash Kher",
        "Roop Kumar Rathod",
        "PT. Hariprasad Chaurasia",
        "Sivamani",
        "Rahul Sharma",
        "Rashid Khan",
        "Vikku Vinayakram",
        "Arijit Singh",
        "Selvaganesh",
        "Pete Lockett (UK)",
        "George Brooks (USA)",
        "Leni Stern (ITALY)",
    ],
    associations_images: [
        require("../image/titan.png"),
        require("../image/tata.png"),
        require("../image/kotak.png"),
        require("../image/lic.png"),
        require("../image/transport_toriusm.png"),
        require("../image/hdfc.png"),
        require("../image/aditya_birla.png"),
        require("../image/orgc.png"),
    ],
    contact_info: {
        address: "3022  A Bima Complex Kalamboli, Mumbai,Mumbai,410218,India",
        email: "adb@gmail.com",
        phone : "9874561230",
    },
    ips_list: [
        {
            header_title: "ARPAN",
            description : "A tribute to vidwan vikku vinayakram an indian classical legend and a grammy award winner, for his endless contribution, by maestros from various genres enjoyed by the creme de la cremes of mumbai",
            images : [
                require("../image/img_1.png"),
                require("../image/img_1.png"),
                require("../image/img_1.png")
            ]
        },
        {
            header_title : "ROCKING N RANG",
            description : "RNR is an electrifying ensemble of some of india’s renowned bands and ace maestros gathering with a mission to poplarize indian classical music amongst the youth garnered an overwhelming response by audiences of varied age groups",
            images : [
                require("../image/img_1.png"),
                require("../image/img_2.png"),
                require("../image/img_3.png")
            ]
        },
        {
            header_title : "AUTHOR",
            description : "Is a father-son duo musical event, that showcase the legacy that brings together some of the most revered and exceptional duos of the industry. Appreciated for the concept and mananagement of the line-up the concert at nehru centre, mumbai",
            images : [
                require("../image/img_3.png"),
                require("../image/img_3.png"),
                require("../image/img_3.png")
            ]
        }
    ],
    artist : [
        {
            img : require("../image/img_2.png"),
            name : "VIKKU VINAYAKRAM",
            content : "A Padma Shri, Padma Bhushan, Grammy Award winner Indian percussionist known for playing Ghatam"
        },
        {
            img : require("../image/round_1.png"),
            name : "VIKKU VINAYAKRAM",
            content : "A Padma Shri, Padma Bhushan, Grammy Award winner Indian percussionist known for playing Ghatam"
        },
        {
            img : require("../image/img_2.png"),
            name : "V.SELVAGANESH",
            content : "A Padma Shri, Padma Bhushan, Grammy Award winner Indian percussionist known for playing Ghatam"
        },
    ]
}