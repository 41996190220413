import React, { useEffect, useState } from 'react';
import logo from "../image/logo.png";
import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
import AliceCarousel from 'react-alice-carousel';
import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';

const client = sanityClient({
    projectId: "hv5hizmq",
    dataset: "production",
    useCdn: true,
});


const builder = imageUrlBuilder(client);

function NewUI() {

    const [artists, setArtists] = useState([]);
    const [data, setData] = useState([]);
    const [galleryData, setGalleryData] = useState([]);

    const handleDragStart = (e) => e.preventDefault();

    const [open, setOpen] = useState(false);
    const [slides, setSlides] = useState([]);
    const [gallery, setGallery] = useState([]);

    useEffect(() => {
        setSlides(
            galleryData.map((image) => ({
                src: image.thumbnail.asset.url,
            }))
        )
    }, [])

    useEffect(() => {
        client
            .fetch('*[_type == "gallery"]')
            .then((galleries) => {
                const dataWithImageUrls = galleries.map((gallery) => {
                    const transformedImages = gallery.images.map((image) => ({
                        ...image,
                        asset: {
                            ...image.asset,
                            url: builder.image(image.asset._ref).url(),
                        },
                    }));

                    const transformedThumbnail = {
                        ...gallery.thumbnail,
                        asset: {
                            ...gallery.thumbnail.asset,
                            url: builder
                                .image(gallery.thumbnail.asset._ref)
                                .url(),
                        },
                    };

                    return {
                        ...gallery,
                        images: transformedImages,
                        thumbnail: transformedThumbnail,
                    };
                });
                setGalleryData(dataWithImageUrls)
            })
            .catch((error) => {
                console.error(
                    "Error fetching gallery data from Sanity:",
                    error
                );
            });
    }, [])


    //for ips
    useEffect(() => {
        client
            .fetch('*[_type == "artists"]')
            .then((result) => {
                result.map((item) => {
                    const getImageUrl = (imageRef) => {
                        if (imageRef && imageRef.asset && imageRef.asset._ref) {
                            const imageUrl = builder
                                .image(imageRef.asset._ref)
                                .url();
                            return imageUrl;
                        }
                        return null;
                    };

                    setArtists((prev) => [
                        ...prev,
                        {
                            name: item.name,
                            image: getImageUrl(item.image),
                        }
                    ])
                });
            })
            .catch((error) => {
                console.error("Error fetching data from Sanity:", error);
            });
    }, []);

    //for client
    useEffect(() => {
        client
            .fetch('*[_type == "associations"]')
            .then((result) => {
                setData([]);
                result.map((item) => {
                    const getImageUrl = (imageRef) => {
                        if (imageRef && imageRef.asset && imageRef.asset._ref) {
                            const imageUrl = builder
                                .image(imageRef.asset._ref)
                                .url();
                            return imageUrl;
                        }
                        return null;
                    };

                    setData((prev) => [
                        ...prev,
                        getImageUrl(item.image),
                    ])
                });
            })
            .catch((error) => {
                console.error("Error fetching data from Sanity:", error);
            });
    }, []);

    return (
        <div className="bg-[#39393c] text-white">
            {/* Header */}
            <header className="flex justify-between items-center p-4">
                <img
                    className="md:w-[200px] w-[150px] h-[70px] ml-2 -mt-2 object-contain"
                    src={logo}
                />
                <div className="text-xl md:text-2xl font-poppins text-white">+91 98218 98220</div>
            </header>

            {/* Hero Section */}
            <section className="relative h-screen bg-cover bg-center m-4 -mt-1" style={{ backgroundImage: "url('https://cdn.sanity.io/images/hv5hizmq/production/92ea5aea5458816695f2d1a0a78025289feb03ac-5760x3840.jpg')" }}>
                <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <h1 className="text-xl md:text-5xl font-poppins font-thin uppercase text-[#cad9e7] text-center">Crafting Immersive Experiences That Bring People Together in Moments of Wonder.</h1>
                </div>
            </section>

            {/* Image and Text Section */}
            <section className="relative py-16 px-4 text-center">
                <div className="absolute inset-0 bg-black bg-opacity-40 flex justify-center items-center">
                    <h1 className="text-2xl md:text-5xl font-poppins font-thin uppercase text-[#cad9e7] text-center">VIVIKTA Transforms Ordinary Spaces Into<br /> Extraordinary, Unforgettable Memories.</h1>
                </div>
                {/* <div className='flex flex-col items-center'>
                    <div class="flex justify-between">
                        <img className='w-[450px] h-[450px] object-contain mt-40 ml-18' src={galleryData[0]?.thumbnail?.asset?.url} />
                        <img className='w-[450px] h-[450px] object-contain ml-96 -mt-18' src={galleryData[1]?.thumbnail?.asset?.url} />
                    </div>

                    <div class="flex -mt-40">
                        <img className='w-[450px] h-[450px] object-contain mt-40 ml-56' src={galleryData[2]?.thumbnail?.asset?.url} />
                        <img className='w-[450px] h-[450px] object-contain ml-72 mt-14' src={galleryData[3]?.thumbnail?.asset?.url} />
                    </div>

                    <div class="flex justify-between">
                        <img className='w-[450px] h-[450px] object-contain mt-0 ml-18' src={galleryData[4]?.thumbnail?.asset?.url} />
                        <img className='w-[450px] h-[450px] object-contain ml-96 mt-40' src={galleryData[5]?.thumbnail?.asset?.url} />
                    </div>
                </div>     */}

                <div className='flex flex-col items-center'>
                    <div className="hidden md:flex justify-between">
                        <img className='w-[450px] h-[450px] md:w-[350px] md:[250px] object-contain mt-40 ml-18' src={galleryData[0]?.thumbnail?.asset?.url} />
                        <img className='w-[450px] h-[450px] md:w-[350px] md:[250px] object-contain xl:ml-96 ml-10 -mt-18' src={galleryData[1]?.thumbnail?.asset?.url} />
                    </div>

                    <div className="hidden md:flex -mt-40">
                        <img className='w-[450px] h-[450px] md:w-[350px] md:[250px] object-contain mt-40 xl:ml-56' src={galleryData[2]?.thumbnail?.asset?.url} />
                        <img className='w-[450px] h-[450px] md:w-[350px] md:[250px] object-contain xl:ml-72 ml-10 mt-14' src={galleryData[3]?.thumbnail?.asset?.url} />
                    </div>

                    <div className="hidden md:flex justify-between">
                        <img className='w-[450px] h-[450px] md:w-[350px] md:[250px] object-contain mt-0 ml-18' src={galleryData[4]?.thumbnail?.asset?.url} />
                        <img className='w-[450px] h-[450px] md:w-[350px] md:[250px] object-contain xl:ml-96 ml-12 mt-40' src={galleryData[5]?.thumbnail?.asset?.url} />
                    </div>

                    <div className="md:hidden flex flex-col items-center">
                        {galleryData.slice(0,6).map((item, index) => (
                            <img
                                key={index}
                                className='w-[250px] h-[250px] object-contain my-2'
                                src={item?.thumbnail?.asset?.url}
                            />
                        ))}
                    </div>
                </div>


                {/* <div className="grid gap-4 md:grid-cols-4 sm:grid-cols-3 grid-cols-3">
                    {galleryData.map((item, i) => (
                        <div
                            key={i}
                            onClick={() => {
                                setOpen(true);
                                setGallery(
                                    item.images.map((image) => ({
                                        src: image.asset.url,
                                    }))
                                );
                            }}
                        >
                            <img src={item.thumbnail.asset.url} alt="" />
                        </div>
                    ))}
                    <PhotoAlbum
                        layout="rows"
                        photos={slides}
                        targetRowHeight={150}
                        onClick={() => setOpen(true)}
                    />
                    <Lightbox
                        open={open}
                        plugins={[Thumbnails]}
                        close={() => setOpen(false)}
                        slides={gallery}
                    />
                </div> */}
            </section>

            {/* Our IPs Section */}
            <h2 className="text-2xl font-extralight font-poppins uppercase md:text-3xl mt-8 mb-4 text-center">Our IPs</h2>
            <section className="bg-[#39393c] py-8">
                <div className="flex justify-center space-x-4">
                    <AliceCarousel
                        responsive={{
                            0: { items: 1 },
                            400: { items: 1 },
                            650: { items: 2 },
                            768: { items: 3 },
                            940: { items: 4 }
                        }}
                        animationDuration={2000}
                        autoPlayStrategy="none"
                        autoPlay
                        infinite
                        disableDotsControls
                        disableButtonsControls
                        items={
                            artists.map((item, index) => (
                                <div key={index} className="flex flex-col items-center justify-center mb-0">
                                    <div key={index} className="flex flex-col items-center justify-center mb-0">
                                        <div key={index} className="flex flex-col items-center justify-center mb-0">
                                            <img className="w-[250px] h-[250px]" src={item?.image || "https://via.placeholder.com/150"} onDragStart={handleDragStart} role="presentation" />
                                            <h1 className="font-extralight text-2xl text-center text-white mt-4">{item?.name || "-"}</h1>
                                        </div>
                                    </div>
                                </div>
                            ))
                        } />
                </div>
            </section>

            {/* Clients Section */}
            <h2 className="text-2xl font-thin md:text-3xl font-poppins uppercase mt-8 mb-4 text-center">Our Clients</h2>
            <section className="py-8 px-4 text-center bg-white">
                <div className="grid md:grid-cols-4 grid-cols-2 mt-8 gap-5">
                    {data.map((item) => (
                        <div className="w-full flex justify-center items-center bg-white rounded-md py-6 px-1">
                            <img src={item} className="w-[180px] h-[70px] object-contain" />
                        </div>
                    ))}
                </div>
            </section>

            {/* Footer */}
            <footer className="bg-[#39393c] p-8 text-center">
                <h2 className="text-xl md:text-3xl font-poppins uppercase text-center">Ready to create something magical?</h2>
                <p className="text-xl md:text-3xl text-[#87798d] font-poppins uppercase text-center">Get in touch and let's make it happen!</p>
            </footer>

            <div className="p-2 text-center text-gray-300 text-xs">
                &copy; 2024 Aurora Digital Solutions. All rights reserved.
            </div>
        </div>
    )
}

export default NewUI