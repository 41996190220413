import React, { useEffect, useState } from 'react'
import AliceCarousel from 'react-alice-carousel';
import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";


const client = sanityClient({
    projectId: "hv5hizmq",
    dataset: "production",
    useCdn: true,
});


const builder = imageUrlBuilder(client);

const images = [
    // Array of image URLs (add more URLs as needed)
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
];

const Artist = () => {

    const [artists, setArtists] = useState([]);
    const [list1, setList1] = useState([]);
    const [list2, setList2] = useState([]);
    const [list3, setList3] = useState([]);

    const handleDragStart = (e) => e.preventDefault();

    useEffect(() => {
        if (artists.length >= 3) {
            const thirdIndex1 = Math.ceil(artists.length / 3);
            const thirdIndex2 = thirdIndex1 * 2;
            setList1(artists.slice(0, thirdIndex1));
            setList2(artists.slice(thirdIndex1, thirdIndex2));
            setList3(artists.slice(thirdIndex2));
        }
    }, [artists])

    console.log({ artists });

    useEffect(() => {
        client
            .fetch('*[_type == "artists"]')
            .then((result) => {
                result.map((item) => {
                    const getImageUrl = (imageRef) => {
                        if (imageRef && imageRef.asset && imageRef.asset._ref) {
                            const imageUrl = builder
                                .image(imageRef.asset._ref)
                                .url();
                            return imageUrl;
                        }
                        return null;
                    };

                    setArtists((prev) => [
                        ...prev,
                        {
                            name: item.name,
                            image: getImageUrl(item.image),
                        }
                    ])
                });
            })
            .catch((error) => {
                console.error("Error fetching data from Sanity:", error);
            });
    }, []);

    return (
        <div className='purple-bg'>

            <h1 className='text-white text-2xl font-medium'>Artists we have collaborated with in the journey so far:</h1>

            <div className='flex flex-col lg:max-w-[1280px] w-full mx-auto mb-2 mt-4 gap-4'>
                <AliceCarousel
                    responsive={{
                        0: { items: 1 },
                        400: { items: 2 },
                        650: { items: 4 },
                        768: { items: 5 },
                        940: { items: 6 }
                    }}
                    animationDuration={2000}
                    autoPlayStrategy="none"
                    autoPlay
                    infinite
                    disableDotsControls
                    disableButtonsControls
                    items={
                        artists.map((item, index) => (
                            <div key={index} className="flex flex-col items-center justify-center mb-4">
                                <div key={index} className="flex flex-col items-center justify-center mb-4">
                                    <div key={index} className="flex flex-col items-center justify-center mb-4">
                                        <img className="w-[150px] h-[150px]" src={list1[index]?.image || "https://via.placeholder.com/150"} onDragStart={handleDragStart} role="presentation" />
                                        <h1 className="font-semibold text-center text-white mt-4">{list1[index]?.name || "-"}</h1>
                                    </div>
                                    <div key={index} className="flex flex-col items-center justify-center mb-4">
                                        <img className="w-[150px] h-[150px]" src={list2[index]?.image || "https://via.placeholder.com/150"} onDragStart={handleDragStart} role="presentation" />
                                        <h1 className="font-semibold text-center text-white mt-4">{list2[index]?.name || "-"}</h1>
                                    </div>
                                    <div key={index} className="flex flex-col items-center justify-center mb-4">
                                        <img className="w-[150px] h-[150px]" src={list3[index]?.image || "https://via.placeholder.com/150"} onDragStart={handleDragStart} role="presentation" />
                                        <h1 className="font-semibold text-center text-white mt-4">{list3[index]?.name || "-"}</h1>
                                    </div>
                                </div>
                            </div>
                        ))
                    } />
            </div>
        </div>
    )
}

export default Artist