import { Redirect, Switch, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Gallery from "./pages/Gallery";
import { useSanity } from "./components/SanityContext";
import NewUI from "./components/NewUI";

function App() {
    const sanityData = useSanity()
    return (
        <div>
            {/* <NavBar /> */}
            <main>
                <Switch>
                    <Route path="/gallery" component={Gallery} />
                    <Route path="/" component={NewUI} />
                    <Redirect to="/not-found" />
                </Switch>
            </main>
            {/* <Footer /> */}
        </div>
    );
}

export default App;
