import React, { useEffect, useState } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Footer from "../components/Footer";
import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

const client = sanityClient({
    projectId: "hv5hizmq",
    dataset: "production",
    useCdn: true,
});

const builder = imageUrlBuilder(client);

const Gallery = () => {
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [slides,setSlides] = useState([]);
    const [gallery, setGallery] = useState([]);

    useEffect(()=>{
        setSlides(
            data.map((image) => ({
                src: image.thumbnail.asset.url,
            }))
        )
    },[])

    useEffect(()=>{
        client
            .fetch('*[_type == "gallery"]')
            .then((galleries) => {
                const dataWithImageUrls = galleries.map((gallery) => {
                    const transformedImages = gallery.images.map((image) => ({
                        ...image,
                        asset: {
                            ...image.asset,
                            url: builder.image(image.asset._ref).url(),
                        },
                    }));

                    const transformedThumbnail = {
                        ...gallery.thumbnail,
                        asset: {
                            ...gallery.thumbnail.asset,
                            url: builder
                                .image(gallery.thumbnail.asset._ref)
                                .url(),
                        },
                    };

                    return {
                        ...gallery,
                        images: transformedImages,
                        thumbnail: transformedThumbnail,
                    };
                });
                setData(dataWithImageUrls)
            })
            .catch((error) => {
                console.error(
                    "Error fetching gallery data from Sanity:",
                    error
                );
            });
    },[])

    return (
        <div className="w-full max-w-[1280px] mx-auto p-3">
            <div className="grid gap-4 md:grid-cols-4 sm:grid-cols-2 grid-cols-1">
                {data.map((item, i) => (
                    <div
                        key={i}
                        onClick={() => {
                            setOpen(true);
                            setGallery(
                                item.images.map((image) => ({
                                    src: image.asset.url,
                                }))
                            );
                        }}
                    >
                        <img src={item.thumbnail.asset.url} alt="" />
                    </div>
                ))}
                <PhotoAlbum
                    layout="rows"
                    photos={slides}
                    targetRowHeight={150}
                    onClick={() => setOpen(true)}
                />
                <Lightbox
                    open={open}
                    plugins={[Thumbnails]}
                    close={() => setOpen(false)}
                    slides={gallery}
                />
            </div>

            <Footer />
        </div>
    );
};

export default Gallery;
