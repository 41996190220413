import React, { useEffect, useState } from "react";
import {
    FaDribbbleSquare,
    FaFacebookSquare,
    FaGithubSquare,
    FaInstagram,
    FaTwitterSquare,
} from "react-icons/fa";

import logo from "../image/logo.png";
import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

const client = sanityClient({
    projectId: "hv5hizmq",
    dataset: "production",
    useCdn: true,
});


const builder = imageUrlBuilder(client);

const Footer = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        client
            .fetch('*[_type == "contact_info"]')
            .then((result) => {
                result.map((item) => {
                    setData((prev) => [
                        ...prev,
                        {
                            header : item.header,
                            address : item.address,
                            email : item.email,
                            phone : item.phone
                        }
                    ])
                });
            })
            .catch((error) => {
                console.error("Error fetching data from Sanity:", error);
            });
    }, []);

    return (
        <div className="max-w-[1240px] border-t mx-auto py-16 px-4 grid lg:grid-cols-4 gap-8 text-black">
            <div>
                <img
                    className="md:w-[200px] w-[150px] h-[70px] ml-2 -mt-2 object-contain"
                    src={logo}
                />
                <p className="py-4">{data[0]?.header}</p>
            </div>
            <div>
                <div className="">
                    <h1 className="text-lg font-medium">Contact Info</h1>
                    <p className="py-4">{data[0]?.address}</p>
                    <p>
                        <span className="text-lg font-medium">Email : </span>{" "}
                        {data[0]?.email}
                    </p>
                    <p>
                        <span className="text-lg font-medium">Contact : </span>{" "}
                        {data[0]?.phone}
                    </p>
                </div>
            </div>
            <div>
                <div className="w-full">
                    <h6 className="text-lg font-medium">Services</h6>
                    <ul>
                        <li className="py-2 text-sm">Musical Concerts</li>
                        <li className="py-2 text-sm">Corporate Events</li>
                        <li className="py-2 text-sm">Government Festivals</li>
                        <li className="py-2 text-sm">Brand Activations</li>
                        <li className="py-2 text-sm">BTL Activities</li>
                    </ul>
                </div>
            </div>
            <div>
                <div className="w-full">
                    <div>
                        <h6 className="text-lg font-medium">Our Locations</h6>
                        <img
                            className="w-full h-24 object-contain "
                            src="https://png.pngtree.com/png-vector/20191027/ourmid/pngtree-map-pointer-icon-cartoon-style-png-image_1880085.jpg"
                        />
                    </div>
                    <div className="flex justify-evenly md:w-[75%] my-4">
                        <a className="cursor-pointer" href={data?.facebook}><FaFacebookSquare size={30} /></a>
                        <a className="cursor-pointer" href={data?.instagram}><FaInstagram size={30} /></a>
                        <a className="cursor-pointer" href={data?.twitter}><FaTwitterSquare size={30} /></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
