import React, { useEffect, useState } from 'react'
import AliceCarousel from 'react-alice-carousel'
import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

const client = sanityClient({
    projectId: "hv5hizmq",
    dataset: "production",
    useCdn: true,
});


const builder = imageUrlBuilder(client);

const UpcomingConcerts = () => {

    const [upcomingConcerts, setUpcomingConcerts] = useState([]);

    const handleDragStart = (e) => e.preventDefault();

    useEffect(() => {
        client
            .fetch('*[_type == "upcoming_concert"]')
            .then((result) => {
                result.map((item) => {
                    const getImageUrl = (imageRef) => {
                        if (imageRef && imageRef.asset && imageRef.asset._ref) {
                            const imageUrl = builder
                                .image(imageRef.asset._ref)
                                .url();
                            return imageUrl;
                        }
                        return null;
                    };

                    setUpcomingConcerts((prev) => [
                        ...prev,
                        {
                            date: item.date,
                            location: item.location,
                            image: getImageUrl(item.image),
                        }
                    ])
                });
            })
            .catch((error) => {
                console.error("Error fetching data from Sanity:", error);
            });
    }, []);

    const data = [
        {
            image: 'https://placehold.it/500x500',
            date: "10 Jul 2024",
            location: "Pune"
        },
        {
            image: 'https://placehold.it/500x500',
            date: "10 Jul 2024",
            location: "Pune"
        },
        {
            image: 'https://placehold.it/500x500',
            date: "10 Jul 2024",
            location: "Pune"
        },
        {
            image: 'https://placehold.it/500x500',
            date: "10 Jul 2024",
            location: "Pune"
        },
        {
            image: 'https://placehold.it/500x500',
            date: "10 Jan 2024",
            location: "Satara"
        },
        {
            image: 'https://placehold.it/500x500',
            date: "9 Jul 2024",
            location: "Mumbai"
        },
    ]

    return (
        <div className='purple-bg'>
            <div className='title-tag !-mt-6'>
                <h1>Upcoming Concerts</h1>
            </div>

            <div className='lg:max-w-[1280px] w-[80%] mx-auto'>
                <AliceCarousel
                    responsive={{
                        0: { items: 1 },
                        400: { items: 1 },
                        650: { items: 2 },
                        768: { items: 3 },
                        940: { items: 4 }
                    }}
                    mouseTracking
                    disableButtonsControls
                    items={
                        upcomingConcerts.map((item) => (
                            <div className='flex flex-col items-center gap-3 p-3'>
                                <img className='w-[200px] h-[200px]' src={item.image} onDragStart={handleDragStart} role="presentation" />
                                <h1 className='text-white font-semibold text-lg'>{item.date}</h1>
                                <h2 className='text-white font-medium'>{item.location}</h2>
                            </div>
                        ))
                    } />
            </div>
        </div>
    )
}

export default UpcomingConcerts