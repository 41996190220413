import React, { useEffect, useState } from "react";
import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

const client = sanityClient({
    projectId: "hv5hizmq",
    dataset: "production",
    useCdn: true,
});


const builder = imageUrlBuilder(client);

const Associations = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        client
            .fetch('*[_type == "associations"]')
            .then((result) => {
                result.map((item) => {
                    const getImageUrl = (imageRef) => {
                        if (imageRef && imageRef.asset && imageRef.asset._ref) {
                            const imageUrl = builder
                                .image(imageRef.asset._ref)
                                .url();
                            return imageUrl;
                        }
                        return null;
                    };

                    setData((prev) => [
                        ...prev,
                        getImageUrl(item.image),
                    ])
                });
            })
            .catch((error) => {
                console.error("Error fetching data from Sanity:", error);
            });
    }, []);

    return (
        <div className="">
            <div className="flex flex-col max-w-[1280px] mx-auto p-10">
                <div className="grid md:grid-cols-4 grid-cols-2 mt-8 gap-5">
                    {data.map((item) => (
                        <div className="w-full flex justify-center items-center bg-white rounded-md py-6 px-1">
                            <img src={item} className="w-[180px] h-[70px] object-contain" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Associations;


{/* <div key={index} className="flex flex-col items-center justify-center mb-4">
                                    <div key={index} className="flex flex-col items-center justify-center mb-4">
                                        <img className="w-[150px] h-[150px]" src={list1[index]?.image || "https://via.placeholder.com/150"} onDragStart={handleDragStart} role="presentation" />
                                        <h1 className="font-semibold text-center text-white mt-4">{list1[index]?.name || "-"}</h1>
                                    </div>
                                    <div key={index} className="flex flex-col items-center justify-center mb-4">
                                        <img className="w-[150px] h-[150px]" src={list2[index]?.image || "https://via.placeholder.com/150"} onDragStart={handleDragStart} role="presentation" />
                                        <h1 className="font-semibold text-center text-white mt-4">{list2[index]?.name || "-"}</h1>
                                    </div>
                                    <div key={index} className="flex flex-col items-center justify-center mb-4">
                                        <img className="w-[150px] h-[150px]" src={list3[index]?.image || "https://via.placeholder.com/150"} onDragStart={handleDragStart} role="presentation" />
                                        <h1 className="font-semibold text-center text-white mt-4">{list3[index]?.name || "-"}</h1>
                                    </div>
                                </div> */}