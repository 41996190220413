import React, { useEffect, useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AliceCarousel from 'react-alice-carousel';
import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

const images = [
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
    'https://via.placeholder.com/150',
];

const client = sanityClient({
    projectId: "hv5hizmq",
    dataset: "production",
    useCdn: true,
});


const builder = imageUrlBuilder(client);

const RecentShowcases = () => {

    const handleDragStart = (e) => e.preventDefault();
    const [recentShow, setRecentShow] = useState([]);
    const [list1, setList1] = useState([]);
    const [list2, setList2] = useState([]);

    useEffect(() => {
        if (recentShow.length >= 2) {
            const middleIndex = Math.ceil(recentShow.length / 2);
            setList1(recentShow.slice(0, middleIndex));
            setList2(recentShow.slice(middleIndex));
        } else {
            setList1(recentShow);
        }
    }, [recentShow])

    useEffect(() => {
        client
            .fetch('*[_type == "recentShow"]')
            .then((result) => {
                result.map((item) => {
                    const getImageUrl = (imageRef) => {
                        if (imageRef && imageRef.asset && imageRef.asset._ref) {
                            const imageUrl = builder
                                .image(imageRef.asset._ref)
                                .url();
                            return imageUrl;
                        }
                        return null;
                    };

                    setRecentShow((prev) => [
                        ...prev,
                        getImageUrl(item.image),
                    ])
                });
            })
            .catch((error) => {
                console.error("Error fetching data from Sanity:", error);
            });
    }, []);

    return (
        <div className='max-w-[1280px] mx-auto mb-5 flex flex-col'>
            <div className='title-tag'>
                <h1>Recent Showcase</h1>
            </div>
            <div className='flex flex-col w-full mx-auto mb-2 -mt-3 gap-4'>
                <AliceCarousel
                    responsive={{
                        0: { items: 1 },
                        400: { items: 2 },
                        650: { items: 3 },
                        768: { items: 4 },
                        940: { items: 5 }
                    }}
                    animationDuration={2000}
                    autoPlayStrategy="none"
                    autoPlay
                    infinite
                    disableDotsControls
                    disableButtonsControls
                    items={
                        list1.map((item, index) => (
                            <div key={index} className="flex flex-col items-center justify-center mb-4">
                                <img className="w-[150px] h-[150px]" src={item} onDragStart={handleDragStart} role="presentation" />
                                <img className="w-[150px] h-[150px] mt-4" src={list2[index] || "https://via.placeholder.com/150"}
                                    onDragStart={handleDragStart} role="presentation" />
                            </div>
                        ))
                    } />
            </div>
        </div>
    )
}

export default RecentShowcases