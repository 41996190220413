import React, { createContext, useContext, useState } from "react";

const ScrollContext = createContext();

export function ScrollProvider({ children }) {
    const [scrollTo, setScrollTo] = useState(null);

    return (
        <ScrollContext.Provider value={{ scrollTo, setScrollTo }}>
            {children}
        </ScrollContext.Provider>
    );
}

export function useScroll() {
    return useContext(ScrollContext);
}
