import React, { useEffect, useState } from 'react';
import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

const client = sanityClient({
    projectId: "hv5hizmq",
    dataset: "production",
    useCdn: true,
});

const builder = imageUrlBuilder(client);

const About = () => {

    const [about, setAbout] = useState([]);

    useEffect(() => {
        client
            .fetch('*[_type == "owner_info"]')
            .then((result) => {
                result.map((item) => {
                    const getImageUrl = (imageRef) => {
                        if (imageRef && imageRef.asset && imageRef.asset._ref) {
                            const imageUrl = builder
                                .image(imageRef.asset._ref)
                                .url();
                            return imageUrl;
                        }
                        return null;
                    };

                    setAbout((prev) => [
                        ...prev,
                        {
                            name : item.name,
                            position : item.position,
                            about : item.about,
                            image : getImageUrl(item.image),
                        }
                    ])
                });
            })
            .catch((error) => {
                console.error("Error fetching data from Sanity:", error);
            });
    }, []);

    return (
        <div className='purple-bg'>
            <div className='max-w-[1280px] mx-auto flex flex-col items-center'>
                <img className='rounded-md w-[250px] h-[250px]' src={about[0]?.image} role="presentation" />
                <h1 className='text-white font-semibold text-2xl mt-3'>{about[0]?.name}</h1>
                <h1 className='text-white font-semibold text-xl mt-1'>{about[0]?.position}</h1>
                <p className='text-white font-medium text-justif text-center mt-2'>{about[0]?.about}</p>
            </div>
        </div>
    )
}

export default About