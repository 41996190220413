import React, { createContext, useContext, useEffect, useState } from "react";
import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

const client = sanityClient({
    projectId: "hv5hizmq",
    dataset: "production",
    useCdn: true,
});

const SanityContext = createContext();

export function useSanity() {
    return useContext(SanityContext);
}

const builder = imageUrlBuilder(client);

export function SanityProvider({ children }) {
    const [data, setData] = useState([]);
    const [combineData, setCombineData] = useState([]);
    const [recentShow, setRecentShow] = useState([]);

    useEffect(() => {
        client
            .fetch('*[_type == "recentShow"]') // Replace with your query
            .then((result) => {
                const dataWithImageUrls = result.map((item) => {

                    // Define a function to convert an image reference to a URL
                    const getImageUrl = (imageRef) => {
                        if (imageRef && imageRef.asset && imageRef.asset._ref) {
                            const imageUrl = builder
                                .image(imageRef.asset._ref)
                                .url();
                            return imageUrl;
                        }
                        return null;
                    };

                    setRecentShow((prev) => [
                        ...prev,
                        getImageUrl(item.image),
                    ])
                    // Transform all image references within the item
                    // const transformedArtist = item.artist.map((artist) => ({
                    //     ...artist,
                    //     img: {
                    //         ...artist.img,
                    //         url: getImageUrl(artist.img),
                    //     },
                    // }));



                    // // Transform all image references within the item's 'ips_list'
                    // const transformedIpsList = item.ips_list.map((ips) => ({
                    //     ...ips,
                    //     images: ips.images.map((image) => ({
                    //         ...image,
                    //         url: getImageUrl(image),
                    //     })),
                    // }));

                    // const transformedAssociationsImages =
                    //     item.associations_images.map((image) => ({
                    //         ...image,
                    //         asset: {
                    //             ...image.asset,
                    //             url: builder.image(image.asset._ref).url(),
                    //         },
                    //     }));

                    return {
                        ...item,
                        // artist: transformedArtist,
                        // ips_list: transformedIpsList,
                        // associations_images: transformedAssociationsImages,
                    };
                });
                // setData(dataWithImageUrls);
            })
            .catch((error) => {
                console.error("Error fetching data from Sanity:", error);
            });
    }, []);

    return (
        <SanityContext.Provider value={data}>{children}</SanityContext.Provider>
    );
}
