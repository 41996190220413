import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ScrollProvider } from "./components/ScrollProvider";
import { SanityProvider } from "./components/SanityContext";

const rootElement = document.getElementById("root");
ReactDOM.render(
    <StrictMode>
        <BrowserRouter>
            <SanityProvider>
                <ScrollProvider>
                    <App />
                </ScrollProvider>
            </SanityProvider>
        </BrowserRouter>
    </StrictMode>,
    rootElement
);
